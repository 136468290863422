import { action, observable } from 'mobx';

import { MediaItem } from '../../api/interfaces/media';
import { MediaService } from '../../media/services/MediaService';
import { FetchStatus, HYDRA_MEMBER_KEY } from '../../api/interfaces/common';
import SupportService from '../../support/services/SupportService';
import SupportItem from '../../api/interfaces/support';

const NUMBER_LAST_MEDIA = 8;

export default class DashboardStore {
  @observable public fetchStatus: FetchStatus = 'idle';
  @observable public supports: SupportItem[] = [];
  @observable public medias: MediaItem[] = [];
  @observable public yourSupports: SupportItem[] = [];

  constructor(
    private mediaService = new MediaService(),
    private supportService = new SupportService()
  ) {}

  @action.bound
  public async fetchSupport() {
    this.fetchStatus = 'loading';
    try {
      const supportResponse = await this.supportService.fetchSupports(
        1,
        { direction: 'desc', field: 'createdAt' },
        {},
        NUMBER_LAST_MEDIA
      );
      this.supports = supportResponse.data[HYDRA_MEMBER_KEY];
    } catch (err) {
      this.fetchStatus = 'error';
    }
  }

  @action.bound
  public async fetchMedia() {
    this.fetchStatus = 'loading';
    try {
      const mediaResponse = await this.mediaService.fetchMedias(
        1,
        { direction: 'desc', field: 'createdAt' },
        {},
        NUMBER_LAST_MEDIA
      );
      this.medias = mediaResponse.data[HYDRA_MEMBER_KEY];
    } catch (err) {
      this.fetchStatus = 'error';
    }
  }

  @action.bound
  public async fetchYourSupport() {
    this.fetchStatus = 'loading';
    try {
      const supportResponse = await this.supportService.fetchSupports(
        1,
        { direction: 'desc', field: 'createdAt' },
        {},
        NUMBER_LAST_MEDIA,
        { me: true }
      );
      this.yourSupports = supportResponse.data[HYDRA_MEMBER_KEY];
    } catch (err) {
      this.fetchStatus = 'error';
    }
  }
}

import { FunnelNavigationGroup, FunnelNavigationValues } from '@bouygues/theme';
import { FunnelNavigationItem } from '@bouygues/theme/src/components/layouts/FunnelNavigation/FunnelNavigation';
import { action, observable } from 'mobx';
import {
  getSupportFunnelSteps,
  SupportFunnelSteps
} from '../constants/supportFunnelSteps';

export interface StepByIdValue {
  id: string;
  parent: string;
  title: string;
  currentSubStepIdx?: number;
  lengthOfSubStep?: number;
  valueIdx?: number;
}

export type StepValue = {
  [key in string]?: string | object | null;
};

export type StepById = {
  [key in string]?: StepByIdValue;
};

export interface InitSteps {
  steps: string[];
  stepById: StepById;
  currentStepIdx: number;
}

export interface SupportStepValueData {
  text: string;
  raw: any;
  type?: SupportFunnelSteps;
}

export interface SupportStepValue {
  step: string;
  data: SupportStepValueData;
}

export default class SupportFunnelGlobalStore {
  @observable public supportStepValue: SupportStepValue[] = [];
  @observable public selectedParent: string = '';
  @observable public supportStepValueByGroup: FunnelNavigationValues = {};
  @observable public supportFunnelSteps:
    | FunnelNavigationGroup[]
    | FunnelNavigationItem[] = getSupportFunnelSteps();
  @observable public currentStep: StepByIdValue = {
    ...this.supportFunnelSteps[0].items?.[0],
    id: this.supportFunnelSteps[0].items?.[0].id || SupportFunnelSteps.THEME,
    title:
      this.supportFunnelSteps[0].items?.[0].title || SupportFunnelSteps.THEME,
    parent: this.supportFunnelSteps[0].id
  };
  @observable public isFinished: boolean = false;
  @observable public initSteps: InitSteps = {
    steps: [],
    stepById: {},
    currentStepIdx: -1
  };
  @observable public totalSteps: number = 0;
  @observable public isOpenFunnelNavMobile: boolean = false;
  @observable public SHOW_TIPS: string[] = [SupportFunnelSteps.FORMAT];

  @action.bound
  public async init(
    steps: FunnelNavigationGroup[] | FunnelNavigationItem[] = this
      .supportFunnelSteps
  ) {
    const initStepsTemp: string[] = [];
    const initStepById: StepById = {};
    let index = 0;

    function lookupStep(
      _steps: FunnelNavigationGroup[] | FunnelNavigationItem[],
      parent?: string
    ) {
      _steps.forEach(
        (step: FunnelNavigationGroup | FunnelNavigationItem, idx: number) => {
          if (step.items && step.items.length) {
            lookupStep(step.items, step.id);
          } else {
            initStepsTemp.push(step.id);
            initStepById[step.id] = {
              ...step,
              parent: parent || step.id,
              currentSubStepIdx: parent ? idx + 1 : 1,
              lengthOfSubStep: parent ? _steps.length : 1,
              valueIdx: index
            };
            index++;
          }
        }
      );
    }

    lookupStep(steps);

    this.initSteps.steps = initStepsTemp;
    this.initSteps.stepById = initStepById;
    // only run for first init
    if (this.initSteps.currentStepIdx < 0) {
      this.nextStep();
    }

    this.totalSteps = (this.supportFunnelSteps as FunnelNavigationGroup[])
      .map((s) => s.items.length)
      .reduce((pre, cur) => pre + cur, 0);
  }

  @action.bound
  public async submitAnswer(
    data: SupportStepValueData,
    step: string = this.currentStep.id || SupportFunnelSteps.THEME
  ) {
    if (this.isFinished) {
      return;
    }

    this.supportStepValue.push({ step, data });
    this.nextStep();
  }
  public async addMoreSupportStepValue(
    data: SupportStepValueData,
    step: string = this.currentStep.id || SupportFunnelSteps.THEME
  ) {
    if (this.isFinished) {
      return;
    }

    this.supportStepValue.push({ step, data });
  }

  @action.bound
  public getAnswer(step: string) {
    const valueIdx = this.initSteps.stepById[step]?.valueIdx || 0;

    if (valueIdx > this.supportStepValue.length) {
      return null;
    }

    if (valueIdx === 0) {
      return this.supportStepValue[valueIdx];
    }

    return this.supportStepValue[valueIdx - 1];
  }

  @action.bound
  public getParent(step: string) {
    return this.initSteps.stepById[step]?.parent;
  }

  @action.bound
  public updateParent() {
    return (this.selectedParent =
      this.currentStep.parent || this.currentStep?.id || this.selectedParent);
  }

  @action.bound
  public updateSupportStepValueByGroup() {
    this.supportStepValueByGroup = this.supportStepValue.reduce(
      (prev: FunnelNavigationValues, cur: SupportStepValue) => {
        const parent = this.getParent(cur.step);
        return {
          ...prev,
          [parent || cur.step]: {
            ...prev[parent || cur.step],
            [cur.step]: cur.data.text
          }
        };
      },
      {}
    );
  }

  @action.bound
  public async goStep(step: string) {
    if (!this.getAnswer(step) || this.currentStep.id === step) {
      return;
    }

    if (this.initSteps.stepById[step]) {
      this.initSteps.currentStepIdx =
        this.initSteps.stepById[step]?.valueIdx || 0;
    }

    this.currentStep = this.initSteps.stepById[step] || this.currentStep;
    const indexDataByStep = this.supportStepValue.findIndex(
      (item) => item.step === step
    );

    this.supportStepValue.splice(indexDataByStep);
    this.isFinished = false;

    this.updateParent();
    this.updateSupportStepValueByGroup();
  }

  @action.bound
  public async nextStep() {
    if (this.initSteps.currentStepIdx < this.initSteps.steps.length - 1) {
      this.initSteps.currentStepIdx++;
      const nextStep = this.initSteps.steps[this.initSteps.currentStepIdx];
      this.currentStep = this.initSteps.stepById[nextStep] || this.currentStep;
    } else {
      this.isFinished = true;
    }
    this.updateParent();
    this.updateSupportStepValueByGroup();
  }

  @action.bound
  public resetDataFunnelSupport() {
    this.initSteps = {
      steps: [],
      stepById: {},
      currentStepIdx: -1
    };
    this.supportStepValue = [];
    this.selectedParent = '';
    this.currentStep = {
      ...this.supportFunnelSteps[0].items?.[0],
      id: this.supportFunnelSteps[0].items?.[0].id || SupportFunnelSteps.THEME,
      title:
        this.supportFunnelSteps[0].items?.[0].title || SupportFunnelSteps.THEME,
      parent: this.supportFunnelSteps[0].id
    };
  }

  @action.bound
  public setSupportFunnelSteps(
    steps: FunnelNavigationGroup[] | FunnelNavigationItem[]
  ) {
    this.supportFunnelSteps = steps;
  }

  @action.bound
  public setIsOpenFunnelNavMobile(value: boolean) {
    this.isOpenFunnelNavMobile = value;
  }
}

import debounce from 'lodash/debounce';
import { action, observable } from 'mobx';
import {
  UserFormData,
  UserResponseData,
  UserSorting,
  ResponseValue
} from '../types';
import {
  HYDRA_MEMBER_KEY,
  HYDRA_TOTAL_ITEMS_KEY
} from '../../api/interfaces/common';
import UsersService from '../services/UsersService';
import { validateMsgs } from '../../core/constants/validateMsgs';

export type FetchStatus = 'idle' | 'loading' | 'success' | 'error';
export type UserItem = UserResponseData;

export default class UsersStore {
  @observable public fetchStatus: FetchStatus = 'idle';
  @observable public modifyStatus: FetchStatus = 'idle';
  @observable public users: UserItem[] = [];
  @observable public usersTotal: number = -1;
  @observable public currentPage: number = 1;
  @observable public sorting: UserSorting = {
    field: 'id',
    direction: 'desc'
  };

  private debouncedFetchUsers: () => void;

  constructor(private userService: UsersService = new UsersService()) {
    this.debouncedFetchUsers = debounce(this.fetchUsers.bind(this), 300);
  }

  @action.bound
  public async fetchUsers({ newPage } = { newPage: this.currentPage }) {
    this.fetchStatus = 'loading';

    const usersResponse = await this.userService.fetchUsers(
      newPage,
      this.sorting
    );

    this.fetchStatus = 'success';
    this.users = usersResponse.data[HYDRA_MEMBER_KEY];
    this.usersTotal = usersResponse.data[HYDRA_TOTAL_ITEMS_KEY];
  }

  @action.bound
  public async onPageChange(newPage: number) {
    if (this.currentPage !== newPage) {
      this.debouncedFetchUsers();
    }
    this.currentPage = newPage;
  }

  @action.bound
  public async createUser({
    email,
    password,
    firstName,
    middleName,
    lastName,
    roles,
    enabled
  }: UserFormData): Promise<ResponseValue> {
    if (this.modifyStatus === 'loading') {
      return null;
    }

    this.modifyStatus = 'loading';

    try {
      const userResponse = await this.userService.createUser({
        email,
        password,
        firstName,
        middleName,
        lastName,
        roles,
        enabled
      });

      this.modifyStatus = 'success';
      this.users.push(userResponse.data);
      this.usersTotal = this.usersTotal + 1;
      setTimeout(() => {
        this.modifyStatus = 'idle';
      }, 300);
      return { error: null };
    } catch (err) {
      return this.handleErrorModifyUser(err);
    }
  }

  @action.bound
  public async updateUser({
    id,
    email,
    password,
    firstName,
    middleName,
    lastName,
    roles,
    enabled
  }: UserFormData): Promise<ResponseValue> {
    if (this.modifyStatus === 'loading' || !id) {
      return null;
    }

    this.modifyStatus = 'loading';

    try {
      const userUpdated = await this.userService.updateUser({
        id,
        email,
        password,
        firstName,
        middleName,
        lastName,
        roles,
        enabled
      });
      this.modifyStatus = 'success';
      this.users = this.users.map((u: UserResponseData) => {
        if (userUpdated.data.id === u.id) {
          return userUpdated.data;
        }
        return u;
      });
      setTimeout(() => {
        this.modifyStatus = 'idle';
      }, 300);
      return { error: null };
    } catch (err) {
      return this.handleErrorModifyUser(err);
    }
  }

  private handleErrorModifyUser(err: any): ResponseValue {
    this.modifyStatus = 'error';
    if (err.response?.data?.violations) {
      return {
        error: {
          email: err.response.data.violations.find(
            (v: any) => v.propertyPath === 'email'
          )?.message
        }
      };
    }
    return {
      error: {
        default: validateMsgs.errorOccurred
      }
    };
  }
}

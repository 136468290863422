import { SelectedFilters } from '@bouygues/theme';
import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import {
  FetchStatus,
  HYDRA_MEMBER_KEY,
  HYDRA_TOTAL_ITEMS_KEY
} from '../../api/interfaces/common';
import {
  LibrarySorting,
  ordereableField,
  ViewMode
} from '../../api/interfaces/library';
import SupportItem, { SupportSearchItem } from '../../api/interfaces/support';
import { ThemeItem } from '../../api/interfaces/supportFunnel';
import SupportService from '../services/SupportService';
import SupportShareLinkService from '../services/SupportShareLinkService';
import DashboardStore from '../../dashboard/stores/DashboardStore';

export default class SupportStore {
  @observable public fetchStatus: FetchStatus = 'idle';
  @observable public fetchSearchStatus: FetchStatus = 'idle';
  @observable public fetchSupportsByOperationStatus: FetchStatus = 'idle';
  @observable public fetchSharedLinkStatus: FetchStatus = 'idle';
  @observable public supports: SupportItem[] = [];
  @observable public currentPage: number = 1;
  @observable public currentPageSupportByOperation: number = 1;
  @observable public filters: SelectedFilters = {};
  @observable public altFilters: SelectedFilters = {};
  @observable public viewMode: ViewMode = 'cards';
  @observable public supportsLength: number = -1;
  @observable public sorting: LibrarySorting = {
    field: 'createdAt',
    direction: 'desc'
  };
  @observable public supportShareLinkId: string = '';
  @observable public fetchSharedSupportStatus: FetchStatus = 'idle';
  @observable public sharedSupport: SupportItem | null = null;
  @observable public supportYears: number[] = [];
  @observable public supportThemes: ThemeItem[] = [];
  @observable public searchResults: SupportSearchItem[] = [];

  private debouncedFetchSupports: (supportOperationId?: string) => void;

  constructor(
    private dashboardStore = new DashboardStore(),
    private supportService = new SupportService(),
    private supportShareLinkService = new SupportShareLinkService()
  ) {
    this.debouncedFetchSupports = debounce(this.fetchSupports.bind(this), 300);
    this.deboundedFetchSearchResults = debounce(
      this.deboundedFetchSearchResults.bind(this),
      500
    );
  }

  @action.bound
  public async updateElementSupport(newSupportItem: SupportItem) {
    const find = this.supports.find((sup) => sup.id === newSupportItem.id);
    if (find) {
      this.supports = this.supports.map((sup) => {
        if (sup.id === newSupportItem.id) {
          return newSupportItem;
        }
        return sup;
      });
    }
  }

  @action.bound
  public async fetchSupports(supportOperationId?: string) {
    if (supportOperationId) {
      this.fetchSupportsByOperationStatus = 'loading';
      const supportsBySupportOperationResponse = await this.supportService.fetchSupports(
        this.currentPageSupportByOperation,
        this.sorting,
        {
          ...this.altFilters,
          'folder.id': [supportOperationId]
        }
      );
      this.fetchSupportsByOperationStatus = 'success';
      this.supports = supportsBySupportOperationResponse.data[HYDRA_MEMBER_KEY];
      this.supportsLength =
        supportsBySupportOperationResponse.data[HYDRA_TOTAL_ITEMS_KEY];
    } else {
      this.fetchStatus = 'loading';
      const supportsResponse = await this.supportService.fetchSupports(
        this.currentPage,
        this.sorting,
        this.altFilters
      );

      this.fetchStatus = 'success';
      this.supports = supportsResponse.data[HYDRA_MEMBER_KEY];
      this.supportsLength = supportsResponse.data[HYDRA_TOTAL_ITEMS_KEY];
    }
  }

  @action.bound
  public onFiltersChange(
    selectedFilters: SelectedFilters,
    supportOperationId?: string
  ) {
    const currentThemes = selectedFilters['theme.id']?.map((i) => {
      return this.supportThemes.find((t) => t.id === i);
    });
    const formatFilter: string[] = [];
    if (currentThemes && currentThemes.length) {
      currentThemes.forEach((theme) =>
        theme?.formats?.forEach((format) =>
          format.offers?.forEach((offer) =>
            offer.templates?.forEach((template) =>
              formatFilter.push(template.id)
            )
          )
        )
      );
    }

    // we use formats filter instance of themes filter
    this.altFilters = {
      ...selectedFilters,
      'template.id': formatFilter,
      ...(supportOperationId && { 'folder.id': [supportOperationId] })
    };
    this.filters = selectedFilters;
    this.currentPage = 1;
    this.currentPageSupportByOperation = 1;
    this.fetchSupports();
  }

  @action.bound
  public toggleViewMode() {
    this.viewMode = this.viewMode === 'cards' ? 'list' : 'cards';
  }

  @action.bound
  public onSortChange(field: ordereableField, supportOperationId?: string) {
    if (this.sorting.field === field) {
      this.sorting.direction =
        this.sorting.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sorting.field = field;
      this.sorting.direction = 'asc';
    }
    this.currentPage = 1;
    this.currentPageSupportByOperation = 1;
    supportOperationId
      ? this.fetchSupports(supportOperationId)
      : this.fetchSupports();
  }

  @action.bound
  public onPageChange(newPage: number, supportOperationId?: string) {
    if (supportOperationId) {
      if (this.currentPageSupportByOperation !== newPage) {
        this.debouncedFetchSupports(supportOperationId);
      }
      this.currentPageSupportByOperation = newPage;
    } else {
      if (this.currentPage !== newPage) {
        this.debouncedFetchSupports();
      }
      this.currentPage = newPage;
    }
  }

  @action.bound
  public async getShareLink(supportId: string) {
    this.supportShareLinkId = '';
    this.fetchSharedLinkStatus = 'loading';
    try {
      const response = await this.supportShareLinkService.createShareLink(
        supportId
      );
      this.supportShareLinkId = response.data.id;
      this.fetchSharedLinkStatus = 'success';
    } catch (err) {
      this.fetchSharedLinkStatus = 'error';
    }
  }

  @action.bound
  public async setShareLinkId(shareLinkId: string) {
    this.supportShareLinkId = shareLinkId;
  }

  @action.bound
  public async fetchSharedSupport(shareLinkId: string) {
    this.fetchSharedSupportStatus = 'loading';
    try {
      const response = await this.supportShareLinkService.getSharedSupport(
        shareLinkId
      );
      this.sharedSupport = response.data.support;
      this.fetchSharedSupportStatus = 'success';
    } catch (err) {
      this.fetchSharedSupportStatus = 'error';
    }
  }

  @action.bound
  public async deleteSupport(
    support: SupportItem,
    supportOperationId?: string
  ) {
    this.fetchStatus = 'loading';
    try {
      await this.supportService.deleteSupport(support.id);
      supportOperationId
        ? this.fetchSupports(supportOperationId)
        : this.fetchSupports();
      // fetch support at dashboard screen
      this.dashboardStore.fetchSupport();
      // fetch your support at dashboard screen
      this.dashboardStore.fetchYourSupport();
      this.fetchStatus = 'success';
    } catch (e) {
      this.fetchStatus = 'error';
    }
  }

  @action.bound
  public async fetchSupportYears() {
    if (!this.supportYears.length) {
      const response = await this.supportService.getSupportYears();
      this.supportYears = response.data[HYDRA_MEMBER_KEY];
    }
  }

  @action.bound
  public async setSupportThemes(themes: ThemeItem[]) {
    this.supportThemes = themes;
  }

  @action.bound
  public getSearchString() {
    if (this.filters.name && this.filters.name.length) {
      return this.filters.name[0];
    }
    return '';
  }

  @action.bound
  public async fetchSearchResults({ name }: { name: string }) {
    this.fetchSearchStatus = 'loading';

    this.deboundedFetchSearchResults({ name });
  }

  @action.bound
  public async setFetchSearchStatus(status: FetchStatus) {
    this.fetchSearchStatus = status;
  }

  @action.bound
  public async setFetchSupportsByOperationStatus(status: FetchStatus) {
    this.fetchSupportsByOperationStatus = status;
  }

  @action.bound
  public async setFetchStatus(status: FetchStatus) {
    this.fetchStatus = status;
  }

  @action.bound
  public async resetSupportLibrary() {
    this.currentPage = 1;
    this.currentPageSupportByOperation = 1;
    this.filters = {};
    this.altFilters = {};
  }

  @action.bound
  public async setCurrentPageSupportByOperation(page: number) {
    this.currentPageSupportByOperation = page;
  }

  @action.bound
  private async deboundedFetchSearchResults({ name }: { name: string }) {
    const currentPage = 1;
    const itemsPerPage = 5;

    const supportsResponse = await this.supportService.searchSupports(
      name,
      currentPage,
      itemsPerPage
    );

    this.fetchSearchStatus = 'success';
    this.searchResults = supportsResponse.data[HYDRA_MEMBER_KEY];
  }
}

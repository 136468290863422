import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import { GetMediaTypesResponseData } from '../../api/interfaces/media';

export default class MediaTypesService extends ApiService {
  public getAll(): Promise<AxiosResponse<GetMediaTypesResponseData>> {
    return this.get(`/media_types`);
  }

  protected getServiceId() {
    return 'mediaTypes';
  }
}

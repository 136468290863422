import { SelectedFilters } from '@bouygues/theme';
import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import {
  FetchStatus,
  HYDRA_MEMBER_KEY,
  HYDRA_TOTAL_ITEMS_KEY
} from '../../api/interfaces/common';
import { LibrarySorting, ViewMode } from '../../api/interfaces/library';
import SupportItem from '../../api/interfaces/support';
import SupportOperationItem from '../../api/interfaces/supportOperation';
import { SupportOperationService } from '../services/SupportOperationService';

export default class SupportOperationStore {
  @observable public fetchStatus: FetchStatus = 'idle';
  @observable public fetchSupportsBySupportOperationStatus: FetchStatus =
    'idle';
  @observable public supportOperations: SupportOperationItem[] = [];
  @observable public supports: SupportItem[] = [];
  @observable public supportsLength: number = -1;
  @observable public currentSupportOperationLibraryPage: number = 1;
  @observable public supportOperationLength: number = 0;
  @observable public currentPage: number = 1;
  @observable public filters: SelectedFilters = {};
  @observable public viewMode: ViewMode = 'cards';
  @observable public sorting: LibrarySorting = {
    field: 'createdAt',
    direction: 'desc'
  };

  private debouncedFetchSupportOperations: () => void;

  constructor(private supportOperationService = new SupportOperationService()) {
    this.debouncedFetchSupportOperations = debounce(
      this.fetchSupportOperations.bind(this),
      300
    );
  }

  @action.bound
  public async fetchSupportOperations() {
    this.fetchStatus = 'loading';
    const supportOperationsResponse = await this.supportOperationService.fetchSupportOperations(
      this.currentPage,
      this.sorting,
      this.filters
    );
    this.fetchStatus = 'success';
    this.supportOperations = supportOperationsResponse.data[HYDRA_MEMBER_KEY];
    this.supportOperationLength =
      supportOperationsResponse.data[HYDRA_TOTAL_ITEMS_KEY];
  }

  @action.bound
  public onPageChange(newPage: number) {
    if (this.currentPage !== newPage) {
      this.debouncedFetchSupportOperations();
    }
    this.currentPage = newPage;
  }

  @action.bound
  public setFetchStatus(status: FetchStatus) {
    this.fetchStatus = status;
  }
}

import React, { createContext, useContext } from 'react';
import AppStore from './AppStore';
import AuthStore from './AuthStore';
import MediaStore from '../../media/stores/MediaStore';
import DashboardStore from '../../dashboard/stores/DashboardStore';
import SupportFunnelStore from '../../supportFunnel/stores/SupportFunnelStore';
import SupportFunnelGlobalStore from './SupportFunnelGlobalStore';
import UsersStore from '../../users/stores/UsersStore';
import SupportStore from '../../support/stores/SupportStore';
import SupportOperationStore from '../../supportOperation/stores/SupportOperationStore';
import InseeCityStore from '../../inseeCity/stores/InseeCityStore';

export interface GlobalStore {
  appStore: AppStore;
  authStore: AuthStore;
  usersStore: UsersStore;
  mediaStore: MediaStore;
  dashboardStore: DashboardStore;
  supportFunnelGlobalStore: SupportFunnelGlobalStore;
  supportFunnelStore: SupportFunnelStore;
  supportStore: SupportStore;
  supportOperationStore: SupportOperationStore;
  inseeCityStore: InseeCityStore;
}

const dashboardStore = new DashboardStore();
const mediaStore = new MediaStore(dashboardStore);
const supportStore = new SupportStore(dashboardStore);
const supportFunnelGlobalStore = new SupportFunnelGlobalStore();

const globalStore: GlobalStore = {
  appStore: new AppStore(),
  authStore: new AuthStore(),
  usersStore: new UsersStore(),
  mediaStore,
  dashboardStore,
  supportStore,
  supportFunnelGlobalStore,
  supportFunnelStore: new SupportFunnelStore({
    supportStore,
    supportFunnelGlobalStore
  }),
  supportOperationStore: new SupportOperationStore(),
  inseeCityStore: new InseeCityStore()
};

const storeContext = createContext<GlobalStore>(globalStore);

export function useGlobalStore() {
  return useContext(storeContext);
}

export const GlobalStoreProvider = ({ children }: any) => {
  return (
    <storeContext.Provider value={globalStore}>
      {children}
    </storeContext.Provider>
  );
};

import { action, observable } from 'mobx';
import { ModalType, ModalTemplate, ModalTransitionType } from '../types';
import useWindowSize from '../hooks/useWindowSize';

enum SCREEN {
  XS = 375,
  SM = 576,
  MD = 768,
  LG = 992
}

const OVERFLOW_HIDDEN = 'overflow-hidden';

export default class AppStore {
  @observable public modals: ModalType[] = [];
  @observable public visibleSideBarMobile = false;
  @observable public visibleOverlay = false;

  @action.bound
  public openModal(
    template: ModalTemplate,
    payload: any = null,
    transitionType: ModalTransitionType = 'fade'
  ) {
    this.modals.push({
      template,
      payload,
      transitionType,
      visible: true
    });
  }

  @action.bound
  public replaceModal(template: ModalTemplate, payload: any = null) {
    const modal = this.modals[this.modals.length - 1];
    if (modal) {
      modal.template = template;
      modal.payload = payload;
    } else {
      this.openModal(template, payload);
    }
  }

  @action.bound
  public closeModal() {
    const modal = this.modals[this.modals.length - 1];
    if (modal) {
      modal.visible = false;
    }
  }

  @action.bound
  public closeAllModal() {
    this.modals = this.modals.map((modal) => {
      return {
        ...modal,
        visible: false
      };
    });
  }

  @action.bound
  public cleanModal() {
    this.modals.pop();
  }

  @action.bound
  public isSmScreen() {
    return useWindowSize().width <= SCREEN.SM;
  }

  @action.bound
  public isMdScreen() {
    return useWindowSize().width <= SCREEN.MD;
  }

  @action.bound
  public isLgScreen() {
    return useWindowSize().width <= SCREEN.LG;
  }

  @action.bound
  public showSideBarMobile() {
    this.visibleSideBarMobile = true;
    document.body.classList.add(OVERFLOW_HIDDEN);
  }

  @action.bound
  public hideSideBarMobile() {
    this.visibleSideBarMobile = false;
    document.body.classList.remove(OVERFLOW_HIDDEN);
  }

  @action.bound
  public showOverlay() {
    this.visibleOverlay = true;
  }

  @action.bound
  public hideOverlay() {
    this.visibleOverlay = false;
  }
}

import { range } from '../core/utils/numberUtils';

export const FILTER_MONTH_ITEMS = [
  {
    id: '1',
    label: 'Janvier'
  },
  {
    id: '2',
    label: 'Février'
  },
  {
    id: '3',
    label: 'Mars'
  },
  {
    id: '4',
    label: 'Avril'
  },
  {
    id: '5',
    label: 'Mai'
  },
  {
    id: '6',
    label: 'Juin'
  },
  {
    id: '7',
    label: 'Juillet'
  },
  {
    id: '8',
    label: 'Aout'
  },
  {
    id: '9',
    label: 'Septembre'
  },
  {
    id: '10',
    label: 'Octobre'
  },
  {
    id: '11',
    label: 'Novembre'
  },
  {
    id: '12',
    label: 'Décembre'
  }
];

export const FILTER_COLOR_ITEMS = [
  {
    id: '3',
    label: 'RVB' // RGB
  },
  {
    id: '4',
    label: 'CMJN' // CYMK
  }
];

export const FILTER_YEAR_ITEMS = range(2000, 2025).map((year) => ({
  id: String(year),
  label: String(year)
}));

export const FILTER_HIGHLIGHT_ITEMS = [
  {
    id: 'SO',
    label: 'Soldes'
  },
  {
    id: 'HI',
    label: "Les bons plans d'hiver"
  },
  {
    id: 'AU',
    label: "Les bons plans d'automne"
  },
  {
    id: 'ET',
    label: "Les bons plans d'été"
  },
  {
    id: 'PR',
    label: 'Les bons plans de printemps'
  },
  {
    id: 'FD',
    label: 'Les French Days'
  },
  {
    id: 'RE',
    label: 'La rentrée'
  },
  {
    id: 'NO',
    label: 'Noël'
  },
  {
    id: 'GJ',
    label: 'Les Grands Jours'
  },
  {
    id: 'BF',
    label: 'Le Black Friday'
  },
  {
    id: 'AP',
    label: 'Apple'
  },
  {
    id: 'SA',
    label: 'Samsung'
  }
];

// Add spaces for thousand separator
// eg: formatUsingSpaces(12342) === '12 342'
export const formatUsingSpaces = (n: number, separator: string = ' ') => {
  let numStr = String(n);
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(numStr)) {
    numStr = numStr.replace(rgx, '$1' + separator + '$2');
  }
  return numStr;
};

// Remove the final .0 of fixed number
const toFixed = (n: number, decimals: number, divider: number = 1) =>
  parseFloat((n / divider).toFixed(decimals));

export const formatOctets = (o: number) => {
  if (o < 1000) {
    return `${o} octets`;
  }
  if (o < 1000000) {
    return `${toFixed(o, 1, 1000)} Ko`;
  }
  if (o < 10e8) {
    return `${toFixed(o, 1, 10e5)} Mo`;
  }
  return `${toFixed(o, 1, 10e8)} Go`;
};

export const range = (start: number, end: number) => {
  if (start > end) {
    throw new Error('Invalid number');
  }
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
};

export const getRatio = (width: number, height: number, fractionDigits = 4) => {
  if (width <= 0 || height <= 0 || fractionDigits < 0 || fractionDigits > 20) {
    throw new RangeError('Invalid values');
  }

  return Number((width / height).toFixed(fractionDigits));
};

const CURRENT_USER_ID_KEY = 'user_id';
const REDIRECT_TO_PATH_KEY = 'redirect_to';

export function storeCurrentUserId(userId: string) {
  localStorage.setItem(CURRENT_USER_ID_KEY, userId);
}

export function retrieveCurrentUserId() {
  return localStorage.getItem(CURRENT_USER_ID_KEY);
}

export function removeCurrentUserId() {
  localStorage.removeItem(CURRENT_USER_ID_KEY);
}

export function storeRedirectToPath(path: string) {
  localStorage.setItem(REDIRECT_TO_PATH_KEY, path);
}

export function retrieveRedirectToPath() {
  return localStorage.getItem(REDIRECT_TO_PATH_KEY);
}

export function removeRedirectToPath() {
  localStorage.removeItem(REDIRECT_TO_PATH_KEY);
}

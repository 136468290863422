import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import { CreateShareLinkResponseData } from '../../api/interfaces/library';
import { GetSupportShareLinkResponseData } from '../../api/interfaces/support';

export default class SupportShareLinkService extends ApiService {
  public createShareLink(
    supportId: string
  ): Promise<AxiosResponse<CreateShareLinkResponseData>> {
    return this.post(`/support_share_links`, {
      supportId
    });
  }

  public getSharedSupport(
    shareLinkId: string
  ): Promise<AxiosResponse<GetSupportShareLinkResponseData>> {
    return this.get(`/support_share_links/${shareLinkId}`);
  }

  protected getServiceId() {
    return 'supportShareLink';
  }
}

import { action, computed, observable } from 'mobx';
import { UserRole } from '../../api/interfaces/auth';
import LoginService from '../../login/services/LoginService';
import UsersService from '../../users/services/UsersService';
import { UserResponseData } from '../../users/types';
import Routes from '../constants/Routes';
import Tracking from '../utils/gaTrackingUtils';
import { removeCurrentUserId, storeCurrentUserId } from '../utils/storageUtils';

type AuthStatus =
  | 'idle'
  | 'loading'
  | 'connected'
  | 'disconnected'
  | 'unauthorized'
  | 'error';

export default class AuthStore {
  @observable public authStatus: AuthStatus = 'idle';
  @observable public data: UserResponseData | null = null;
  @observable public authError: boolean = false;
  @observable public loading: boolean = false;

  constructor(
    private loginService: LoginService = new LoginService(),
    private usersService = new UsersService()
  ) {}

  @computed get userRole(): UserRole | null {
    if (this.data === null) {
      return null;
    }
    return this.data.roles[0];
  }

  @computed get isConnected(): boolean {
    return this.authStatus === 'connected';
  }

  @action.bound
  public async fetchCurrentUser(userId: string) {
    this.loading = true;
    this.authStatus = 'loading';
    try {
      const userResponse = await this.usersService.getUser(userId);
      this.data = userResponse.data;
      this.authStatus = 'connected';
      Tracking.trackEvent({
        category: Tracking.ACTION,
        action: Tracking.retrieveProfile.action,
        label: String(this.userRole)
      });
    } catch (err) {
      this.authStatus = 'unauthorized';
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async authenticate(email: string, password: string) {
    this.authError = false;
    this.loading = true;
    try {
      const loginResponse = await this.loginService.login(email, password);
      const userId = loginResponse.data.id;
      const userResponse = await this.usersService.getUser(userId);
      this.data = userResponse.data;
      this.authStatus = 'connected';
      storeCurrentUserId(userId);
      Tracking.trackEvent({
        category: Tracking.ACTION,
        action: Tracking.login.action,
        label: String(this.userRole)
      });
    } catch (err) {
      this.authStatus = 'unauthorized';
      this.authError = true;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async logout() {
    removeCurrentUserId();
    await this.loginService.logout();
    window.location.href = Routes.Login; // To remove all the data in store
  }

  @action.bound
  public async setStatusUnauthorized() {
    this.authStatus = 'unauthorized';
  }

  @action.bound
  public clearError() {
    this.authStatus = 'idle';
    this.authError = false;
  }
}

import {
  FILTER_YEAR_ITEMS,
  FILTER_MONTH_ITEMS,
  FILTER_HIGHLIGHT_ITEMS
} from '../library/constants';

export const API_URL = process.env.REACT_APP_API_URL as string;

export const MEDIA_TYPES: { [k: string]: string } = {
  photo: 'Photographie',
  nature: 'Nature'
};

export const VIDEO_FORMAT = [
  'avi',
  'mpeg',
  'ogv',
  'webm',
  '3gp',
  '3g2',
  'mp4',
  'mov'
];

export const FILE_FORMAT_BACKGROUND: any = {
  default: '#02455e',
  zip: '#082136',
  pdf: '#109DB9',
  indd: '#02455e',
  mp4: '#002138',
  mov: '#002138'
};

export const MONTH_OPTIONS = FILTER_MONTH_ITEMS.map(({ id, label }) => ({
  value: id,
  label
}));

export const MAP_MONTH_TO_STRING = FILTER_MONTH_ITEMS.reduce(
  (prev, { id, label }) => {
    return {
      ...prev,
      [id]: label.toLowerCase()
    };
  },
  {} as { [k: string]: string }
);

export const YEAR_OPTIONS = FILTER_YEAR_ITEMS.map(({ id, label }) => ({
  value: Number(id),
  label
}));

export const HIGHLIGHT_OPTIONS = FILTER_HIGHLIGHT_ITEMS.map(
  ({ id, label }) => ({ value: id, label })
);

export const MAP_PREFIX_MEDIA_TYPE: { [k: string]: string } = {
  native: 'natif',
  advisers: 'visuel',
  various: 'visuel',
  landscapes: 'visuel',
  products: 'visuel',
  natures: 'visuel',
  pattern: 'pattern',
  logo: 'logo',
  pictos: 'picto',
  other: '',
  support_crozon: 'crozon',
  support_ftth: 'ftth',
  support_convergence: 'convergence',
  support_4g_box: '4g_box',
  support_other: 'support'
};

import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import { GetInseeCityResponseData } from '../../api/interfaces/inseeCity';

const ITEMS_PER_PAGE = 12;

interface FetchInseeCityParams {
  nomCommuneComplet: string;
  page?: number;
  itemsPerPage?: number;
}

export default class InseeCityService extends ApiService {
  public fetchInseeCity({
    nomCommuneComplet,
    page = 1,
    itemsPerPage = ITEMS_PER_PAGE
  }: FetchInseeCityParams): Promise<AxiosResponse<GetInseeCityResponseData>> {
    return this.get('/insee_cities', {
      nomCommuneComplet,
      page,
      itemsPerPage
    });
  }

  protected getServiceId() {
    return 'inseeCity';
  }
}

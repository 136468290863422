export enum SupportFunnelSteps {
  SUPPORT_HEAD = 'support_head',
  PERSONAL = 'personal',
  RECAP = 'recap',
  THEME = 'theme',
  SUPPORT = 'support',
  OFFER = 'offer',
  DATE = 'date',
  MEDIA = 'media',
  DISTRICT = 'district',
  FORMAT = 'format',
  NAME = 'name',
  CITY = 'city',
  SHOP = 'shop',
  FILE = 'file',
  COMMON = 'common',
  OPERATION = 'operation',
  INSEE_CITY = 'inseeCity',
  NONE_PERSONAL = 'none_personal',
  NONE_SHOP = 'none_shop',
  NOQR = 'noqr',
  QR = 'qr',
  PRESS = 'press'
}

export enum SupportFunnelStepNames {
  SUPPORT_HEAD = 'Support',
  PERSONAL = 'Personnalisation',
  RECAP = 'Récapitulatif',
  THEME = 'Sujet',
  SUPPORT = 'Support',
  OFFER = 'Offre',
  DATE = 'Date',
  MEDIA = 'Informations du partenaire, CE',
  DISTRICT = 'Département',
  FORMAT = 'Format',
  NAME = 'Fichier',
  CITY = 'Ville',
  SHOP = 'Renvoi Boutique',
  NONE = 'Non',
  PRESS = 'Format'
}

// CLASSIC: Date - Département - Ville - Boutique
// CLASSIC_SINGLE_DATE: Date(single) - Département - Ville - Boutique
// CLASSIC_NO_DATE: Département - Ville - Boutique
// CLASSIC_ONLY_SINGLE_DATE: Date(single)
// CLASSIC_WITH_IMAGE: Date - STEP IMAGE TBD - Département - Ville - Boutique
// CLASSIC_WITH_5G_CITIES: 5G City
export type SupportFunnelTypes =
  | 'CLASSIC'
  | 'CLASSIC_SINGLE_DATE'
  | 'CLASSIC_NO_DATE'
  | 'CLASSIC_ONLY_SINGLE_DATE'
  | 'CLASSIC_WITH_IMAGE'
  | 'CLASSIC_WITH_IMAGE_NO_SHOP'
  | 'CLASSIC_WITH_5G_CITIES';

export function getSupportFunnelSteps() {
  return [
    {
      id: SupportFunnelSteps.PERSONAL,
      title: SupportFunnelStepNames.PERSONAL,
      items: [
        {
          id: SupportFunnelSteps.THEME,
          title: SupportFunnelStepNames.THEME
        },
        {
          id: SupportFunnelSteps.FORMAT,
          title: SupportFunnelStepNames.SUPPORT
        },
        {
          id: SupportFunnelSteps.PRESS,
          title: SupportFunnelStepNames.PRESS
        },
        {
          id: SupportFunnelSteps.OFFER,
          title: SupportFunnelStepNames.OFFER
        },
        {
          id: SupportFunnelSteps.PERSONAL,
          title: SupportFunnelStepNames.PERSONAL
        },
        {
          id: SupportFunnelSteps.SHOP,
          title: SupportFunnelStepNames.SHOP
        }
      ]
    },
    {
      id: SupportFunnelSteps.RECAP,
      title: SupportFunnelStepNames.RECAP,
      items: []
    }
  ];
}

export const moveThemesIntoAnotherFormat = {
  fromThemeId: ['ce', 'web'],
  targetThemeId: 'crozon'
};

import React, { useEffect, useState } from 'react';
import cs from 'classnames';

import './Loader.scss';

interface Props {
  color?: 'white' | 'black' | 'blue';
  isFullScreen?: boolean;
  delayMs?: number;
  fitContainer?: boolean;
}

export default function Loader({
  color = 'white',
  isFullScreen = false,
  delayMs = 0,
  fitContainer = false
}: Props) {
  const [isVisible, setIsVisible] = useState(delayMs === 0);
  useEffect(() => {
    if (delayMs > 0) {
      const timeout = setTimeout(() => setIsVisible(true), delayMs);
      return () => window.clearTimeout(timeout);
    }
    return;
  }, [delayMs]);
  if (!isVisible) {
    return null;
  }
  return (
    <div
      className={cs('loader', `loader--${color}`, {
        'loader--fullscreen': isFullScreen,
        'loader--fit': fitContainer
      })}
    />
  );
}

import {
  GetMediaConfigResponseData,
  GetMediasResponseData,
  GetMediaYearsResponseData,
  MediaItem,
  MediaProvider,
  GetSearchMediasResponseData
} from '../../api/interfaces/media';
import ApiService from '../../api/services/ApiService';
import { AxiosResponse } from 'axios';
import { LibrarySorting } from '../../api/interfaces/library';

const ITEMS_PER_PAGE = 12;

export class MediaService extends ApiService {
  private numberOfResults: number = -1;

  public uploadMedia(
    file: File,
    name: string,
    typeId: string,
    provider: MediaProvider,
    highlight?: string,
    uploadDate?: string
  ): Promise<AxiosResponse<MediaItem>> {
    return this.postMultipartForm('/media/upload', {
      file,
      name,
      typeId,
      highlight,
      uploadDate,
      provider
    });
  }

  public uploadChunkMedia(
    file: File | Blob,
    name: string,
    typeId: string,
    provider: MediaProvider,
    highlight?: string,
    uploadDate?: string,
    headers?: { [k: string]: any }
  ): Promise<AxiosResponse<MediaItem>> {
    return this.postChunkFileUpload(
      '/media/upload',
      file,
      // Query string
      {
        name,
        typeId,
        highlight,
        uploadDate,
        provider
      },
      headers
    );
  }

  public getNumberOfResults() {
    return this.numberOfResults;
  }

  public fetchMedias(
    page: number = 1,
    sorting: LibrarySorting,
    filters: { [key: string]: any[] },
    itemsPerPage: number = ITEMS_PER_PAGE
  ): Promise<AxiosResponse<GetMediasResponseData>> {
    const filtersParams: any = {};
    Object.keys(filters).forEach((k: string) => {
      if (k === 'name' && filters[k].length) {
        filtersParams[k] = filters[k][0];
      } else if (k === 'contentType') {
        filtersParams[k] = filters[k]
          .map((strLikeArr: string) => strLikeArr.split(','))
          .reduce((prev, curr) => prev.concat(curr), []);
      } else {
        filtersParams[k] = [...filters[k]];
      }
    });
    return this.get('/media', {
      page,
      [`order[${sorting.field}]`]: sorting.direction,
      ...filtersParams,
      itemsPerPage
    });
  }

  public searchMedia(
    name: string,
    page: number = 1,
    itemsPerPage: number = ITEMS_PER_PAGE
  ): Promise<AxiosResponse<GetSearchMediasResponseData>> {
    return this.get(`/media/names/${name}`, {
      page,
      itemsPerPage
    });
  }

  public deleteMedia(id: string): Promise<AxiosResponse> {
    return this.delete(`/media/${id}`);
  }

  public getMediaYears(): Promise<AxiosResponse<GetMediaYearsResponseData>> {
    return this.get(`/media/years`);
  }

  public getMediaConfig(): Promise<AxiosResponse<GetMediaConfigResponseData>> {
    return this.get(`/media/configuration`);
  }

  protected getServiceId() {
    return 'media';
  }
}

import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import { FetchStatus, HYDRA_MEMBER_KEY } from '../../api/interfaces/common';
import { InseeCityItem } from '../../api/interfaces/inseeCity';
import InseeCityService from '../services/InseeCityService';

export default class InseeCityStore {
  @observable public fetchSearchStatus: FetchStatus = 'idle';
  @observable public searchResults: InseeCityItem[] = [];

  constructor(private inseeCityService = new InseeCityService()) {
    this.deboundedFetchSearchResults = debounce(
      this.deboundedFetchSearchResults.bind(this),
      500
    );
  }

  @action.bound
  public async setSearchResults(results: InseeCityItem[]) {
    this.searchResults = results;
  }

  @action.bound
  public async setFetchSearchStatus(status: FetchStatus) {
    this.fetchSearchStatus = status;
  }

  @action.bound
  public async fetchSearchResults({ name }: { name: string }) {
    this.fetchSearchStatus = 'loading';

    this.deboundedFetchSearchResults({ name });
  }

  @action.bound
  private async deboundedFetchSearchResults({ name }: { name: string }) {
    const inseeCityResponse = await this.inseeCityService.fetchInseeCity({
      nomCommuneComplet: name
    });

    this.fetchSearchStatus = 'success';
    this.searchResults = inseeCityResponse.data[HYDRA_MEMBER_KEY];
  }
}

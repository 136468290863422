enum Routes {
  Index = '/',
  Login = '/login',
  SharedMedia = '/shared/media/:sharedMediaId',
  SharedSupport = '/shared/support/:sharedSupportId',
  Dashboard = '/dashboard',
  MediaLibrary = '/medias',
  SupportLibrary = '/supports',
  SupportOperation = '/support-operation',
  SupportOperationLibrary = '/support-operation/:supportOperationId',
  UserManagement = '/users',
  ContactUs = '/contact'
}

export default Routes;

import axios from 'axios';
import Routes from '../../core/constants/Routes';
import { API_URL } from '../../media/constants';

interface RequestParams {
  [k: string]: any;
}

interface ApiServiceInstances {
  [serviceId: string]: ApiService;
}

const CONTENT_TYPE = 'Content-Type';

axios.defaults.withCredentials = true;
axios.defaults.headers.common.Accept = 'application/ld+json';
axios.interceptors.response.use(undefined, (error) => {
  if (error.response) {
    const url: string = error.response.config.url;
    const statusText: string = error.response.statusText;
    if (
      !url.includes('/users') &&
      statusText === 'Unauthorized' &&
      window.location.pathname !== Routes.Login
    ) {
      window.location.href = Routes.Login;
    }
  }
  return Promise.reject(error);
});

export default abstract class ApiService {
  private static instances: ApiServiceInstances = {};
  private API_URL: string = API_URL;

  constructor() {
    const serviceId = this.getServiceId();
    const instance = ApiService.instances[serviceId];

    if (instance) {
      return instance;
    }
    ApiService.instances[serviceId] = this;
  }

  protected abstract getServiceId(): string;

  protected get(url: string, params?: RequestParams) {
    return axios.get(this.makeURL(url, params));
  }

  protected post(url: string, requestBody: any, params?: RequestParams) {
    return axios.post(this.makeURL(url, params), requestBody, {
      headers: { [CONTENT_TYPE]: 'application/json' }
    });
  }

  protected patch(url: string, requestBody: any, params?: RequestParams) {
    return axios.patch(this.makeURL(url, params), requestBody, {
      headers: { [CONTENT_TYPE]: 'application/merge-patch+json' }
    });
  }

  protected delete(url: string, params?: RequestParams) {
    return axios.delete(this.makeURL(url, params));
  }

  protected async postMultipartForm(
    url: string,
    requestBody: any,
    params?: RequestParams
  ) {
    const formData = new FormData();
    Object.keys(requestBody).forEach((key) => {
      formData.append(key, requestBody[key]);
    });
    return axios.post(this.makeURL(url, params), formData, {
      headers: {
        [CONTENT_TYPE]: 'multipart/form-data'
      }
    });
  }

  protected async postChunkFileUpload(
    url: string,
    fileRequestBody: File | Blob,
    extraParams: any,
    headers?: { [k: string]: string }
  ) {
    return axios.post(this.makeURL(url, extraParams), fileRequestBody, {
      headers
    });
  }

  private makeURL(url: string, params: RequestParams = {}) {
    return this.API_URL + url + this.makeRequestParams(params);
  }

  private makeRequestParams(params: RequestParams): string {
    const paramKeys = Object.keys(params);
    if (!paramKeys.length) {
      return '';
    }
    return (
      '?' +
      paramKeys
        .map((k: string) => {
          if (Array.isArray(params[k])) {
            return params[k].map((ak: any) => `${k}[]=${ak}`).join('&');
          }
          return `${k}=${encodeURIComponent(params[k])}`;
        })
        .join('&')
    );
  }
}

import { AxiosResponse } from 'axios';
import { LibrarySorting } from '../../api/interfaces/library';
import {
  GetSupportsResponseData,
  GetSupportYearsResponseData,
  GetSearchSupportsResponseData
} from '../../api/interfaces/support';
import ApiService from '../../api/services/ApiService';

const ITEMS_PER_PAGE = 12;

interface OptionalParams {
  me?: boolean;
}

export default class SupportService extends ApiService {
  public fetchSupports(
    page: number = 1,
    sorting: LibrarySorting,
    filters: any,
    itemsPerPage: number = ITEMS_PER_PAGE,
    optional: OptionalParams = {}
  ): Promise<AxiosResponse<GetSupportsResponseData>> {
    const filtersParams: any = {};
    Object.keys(filters).forEach((k: string) => {
      if (k === 'name' && filters[k].length) {
        filtersParams[k] = filters[k][0];
      } else {
        filtersParams[k] = [...filters[k]];
      }
    });
    return this.get('/supports', {
      page,
      [`order[${sorting.field}]`]: sorting.direction,
      ...filtersParams,
      itemsPerPage,
      ...optional
    });
  }

  public searchSupports(
    name: string,
    page: number = 1,
    itemsPerPage: number = ITEMS_PER_PAGE
  ): Promise<AxiosResponse<GetSearchSupportsResponseData>> {
    return this.get(`/supports/names/${name}`, {
      page,
      itemsPerPage
    });
  }

  public deleteSupport(id: string): Promise<AxiosResponse> {
    return this.delete(`/supports/${id}`);
  }

  public getSupportYears(): Promise<
    AxiosResponse<GetSupportYearsResponseData>
  > {
    return this.get('/supports/years');
  }

  protected getServiceId() {
    return 'support';
  }
}

import ReactGA from 'react-ga';

const trackingCode = process.env.REACT_APP_TRACKING_CODE as string;

class Tracking {
  public static ACTION = 'Action';
  public static upload = {
    action: 'Upload media'
  };
  public static share = {
    action: 'Share link'
  };
  public static funnel = {
    action: 'PDF generation at the end of funnel'
  };
  public static download = {
    media: 'Download media',
    support: 'Download support'
  };
  public static login = {
    action: 'User login successfully'
  };
  public static retrieveProfile = {
    action: 'Retrieve profile'
  };

  public static init() {
    ReactGA.initialize(trackingCode);
  }

  public static trackPageView() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public static trackEvent({ category, action, label }: ReactGA.EventArgs) {
    ReactGA.event({
      category,
      action,
      label
    });
  }
}

export default Tracking;

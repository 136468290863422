import { action, observable } from 'mobx';
import { FetchStatus, HYDRA_MEMBER_KEY } from '../../api/interfaces/common';
import SupportItem from '../../api/interfaces/support';
import {
  CityItem,
  DistrictItem,
  FolderItem,
  FormatItem,
  PostUpdateSupportPDFRequestBody,
  ShopItem,
  SupportDates,
  ThemeItem,
  MediasItem,
  PostSupportPDFRequestBody,
  OfferItem,
  TipItem,
  CustomSize,
  PageOrientation
} from '../../api/interfaces/supportFunnel';
import { getCurrentDate } from '../constants';
import SupportShareLinkService from '../../support/services/SupportShareLinkService';
import SupportFunnelService from '../services/SupportFunnelService';
import SupportFunnelGlobalStore from '../../core/stores/SupportFunnelGlobalStore';
import SupportStore from '../../support/stores/SupportStore';
import {
  moveThemesIntoAnotherFormat,
  SupportFunnelSteps
} from '../../core/constants/supportFunnelSteps';
import { InseeCityItem } from '../../api/interfaces/inseeCity';
import { getRatio } from '../../core/utils/numberUtils';

export default class SupportFunnelStore {
  @observable public fetchDistrictsStatus: FetchStatus = 'idle';
  @observable public fetchCitiesStatus: FetchStatus = 'idle';
  @observable public fetchShopsStatus: FetchStatus = 'idle';
  @observable public fetchThemesStatus: FetchStatus = 'idle';
  @observable public fetchFormatsStatus: FetchStatus = 'idle';
  @observable public fetchOffersStatus: FetchStatus = 'idle';
  @observable public fetchTipsStatus: FetchStatus = 'idle';
  @observable public fetchFoldersStatus: FetchStatus = 'idle';
  @observable public fetchSharedPdfStatus: FetchStatus = 'idle';
  @observable public fetchPostFolderStatus: FetchStatus = 'idle';
  @observable public updateSupportStatus: FetchStatus = 'idle';
  @observable public generatePDFStatus: FetchStatus = 'idle';
  @observable public previewPDFStatus: FetchStatus = 'idle';
  @observable public themes: ThemeItem[] = [];
  @observable public formats: FormatItem[] = [];
  @observable public offers: OfferItem[] = [];
  @observable public tips: TipItem[] = [];
  @observable public folders: FolderItem[] = [];
  @observable public districts: DistrictItem[] = [];
  @observable public cities: CityItem[] = [];
  @observable public shops: ShopItem[] = [];
  @observable public customSize: CustomSize | null = null;
  @observable public pageOrientation: PageOrientation | null = null;
  @observable public selectedTheme: ThemeItem | null = null;
  @observable public selectedFormat: FormatItem | null = null;
  @observable public selectedOffer: OfferItem | null = null;
  @observable public selectedPersonalTemplate: SupportFunnelSteps =
    SupportFunnelSteps.NONE_PERSONAL;
  @observable public selectedShopTemplate: boolean = false;
  @observable public selectedFolder: FolderItem | null = null;
  @observable public selectedDates: SupportDates | null = null;
  @observable public selectedMedias: MediasItem | null = null;
  @observable public selectedDistrict: DistrictItem | null = null;
  @observable public selectedCityNameAlt: string = '';
  @observable public selectedCities: CityItem[] | null = null;
  @observable public selectedInseeCity: InseeCityItem | null = null;
  @observable public selectedShops: ShopItem[] | null = null;
  @observable public generatedPDF: SupportItem | null = null;
  @observable public sharedPdf: SupportItem | null = null;
  @observable public newSupportName: string = '';
  @observable public supportName: string = '';
  @observable public isCropMark: boolean = false;
  @observable public referenceName: string = ''; // Just for FAB user
  @observable public printingCompany: string = ''; // Just for FAB user
  @observable public banniereWebList: string[] = [
    'mobile_bannire_web_15_rsp_v0',
    'fibre_bannire_web_15_rsp_v1',
    'fibre_bannire_web_15_rv_v0'
  ];

  private supportFunnelService: SupportFunnelService;
  private supportShareLinkService: SupportShareLinkService;
  private supportFunnelGlobalStore: SupportFunnelGlobalStore;
  private supportStore: SupportStore;

  constructor({
    supportFunnelService = new SupportFunnelService(),
    supportShareLinkService = new SupportShareLinkService(),
    supportFunnelGlobalStore = new SupportFunnelGlobalStore(),
    supportStore = new SupportStore()
  } = {}) {
    this.supportFunnelService = supportFunnelService;
    this.supportShareLinkService = supportShareLinkService;
    this.supportFunnelGlobalStore = supportFunnelGlobalStore;
    this.supportStore = supportStore;
  }

  @action.bound
  public async fetchDistricts(params: {
    itemsPerPage: number;
    keyword: string;
  }) {
    this.fetchDistrictsStatus = 'loading';
    try {
      const districtsResponse = await this.supportFunnelService.getDistricts(
        params
      );
      this.fetchDistrictsStatus = 'success';
      this.districts = districtsResponse.data[HYDRA_MEMBER_KEY];
    } catch (err) {
      this.fetchDistrictsStatus = 'error';
    }
  }

  @action.bound
  public async fetchCities(params: {
    itemsPerPage: number;
    keyword: string;
    existsCityEndAdslYear?: boolean;
  }) {
    this.fetchCitiesStatus = 'loading';
    try {
      const citiesResponse = await this.supportFunnelService.getCities({
        itemsPerPage: params.itemsPerPage,
        ...(params.existsCityEndAdslYear
          ? { 'exists[cityEndAdslYear]': true }
          : { keyword: params.keyword })
      });
      this.fetchCitiesStatus = 'success';
      this.cities = citiesResponse.data[HYDRA_MEMBER_KEY];
    } catch (err) {
      this.fetchCitiesStatus = 'error';
    }
  }

  @action.bound
  public async fetchShops(params: { itemsPerPage: number; keyword: string }) {
    this.fetchShopsStatus = 'loading';
    try {
      const shopsResponse = await this.supportFunnelService.getShops(params);
      this.fetchShopsStatus = 'success';
      this.shops = shopsResponse.data[HYDRA_MEMBER_KEY];
    } catch (err) {
      this.fetchShopsStatus = 'error';
    }
  }
  @action.bound
  // tslint:disable-next-line: no-big-function cognitive-complexity
  public async fetchThemes() {
    if (!this.themes.length) {
      this.fetchThemesStatus = 'loading';
      const themesResponse = await this.supportFunnelService.getThemes();
      // Save themeId into each format
      const themes: ThemeItem[] = themesResponse.data[HYDRA_MEMBER_KEY].map(
        ({ formats = [], ...theme }) => ({
          ...theme,
          formats: formats.map((format) => ({
            ...format,
            themeName: theme.name,
            themeId: theme.id,
            offers: format.offers?.map((offer) => ({
              ...offer,
              formatName: format.name,
              formatId: format.id,
              conditions: offer.conditions
                ? {
                    ...offer.conditions
                  }
                : null
            })),
            tips: format.tips
              ? {
                  ...format.tips,
                  title: format.name
                }
              : undefined
          }))
        })
      );

      const formatsByThemeId = themes.reduce(
        (accumulator, { id, formats = [] }) => ({
          ...accumulator,
          [id]: formats
        }),
        {} as Record<string, FormatItem[]>
      );

      this.themes = themes.reduce((acc: ThemeItem[], theme) => {
        // Move to another formats, not concat to themes
        if (moveThemesIntoAnotherFormat.fromThemeId.includes(theme.id)) {
          return acc;
        }

        // Merge formats
        if (theme.id === moveThemesIntoAnotherFormat.targetThemeId) {
          const mergedFormats = moveThemesIntoAnotherFormat.fromThemeId.reduce(
            (previousFormats: FormatItem[], movedThemeId: string) => {
              const movedformats = formatsByThemeId[movedThemeId];
              if (movedformats) {
                return previousFormats.concat(movedformats);
              }
              return previousFormats;
            },
            []
          );
          const newTheme: ThemeItem = {
            ...theme,
            formats: (theme.formats || []).concat(mergedFormats)
          };
          return acc.concat([newTheme]);
        }

        return acc.concat([theme]);
      }, []);

      this.fetchThemesStatus = 'success';
    }
  }

  @action.bound
  public async fetchFormats(themeId: string) {
    this.fetchFormatsStatus = 'loading';
    const formatsFromTheme = this.themes.find((t) => t.id === themeId);
    if (formatsFromTheme) {
      this.formats = formatsFromTheme.formats || [];
      this.fetchFormatsStatus = 'success';
    } else {
      this.fetchFormatsStatus = 'error';
    }
  }

  @action.bound
  public async fetchOffers(formatId: string) {
    this.fetchOffersStatus = 'loading';

    const offersFromFormat = this.formats.find((t) => t.id === formatId);
    if (offersFromFormat) {
      this.offers = offersFromFormat.offers || [];
      this.fetchOffersStatus = 'success';
    } else {
      this.fetchOffersStatus = 'error';
    }
  }

  @action.bound
  public async fetchTips(themeId: string) {
    this.fetchTipsStatus = 'loading';

    const tipsFromFormat = this.formats.reduce((newArr: TipItem[], t) => {
      if (t.themeId === themeId) {
        newArr.push(t.tips as TipItem);
      }
      return newArr;
    }, []);
    if (Array.isArray(tipsFromFormat) && tipsFromFormat.length > 0) {
      this.tips = tipsFromFormat || [];
      this.fetchTipsStatus = 'success';
    } else {
      this.fetchTipsStatus = 'error';
    }
  }

  @action.bound
  public async fetchFolders() {
    this.fetchFoldersStatus = 'loading';
    try {
      const foldersResponse = await this.supportFunnelService.getFolders();
      this.fetchFoldersStatus = 'success';
      this.folders = foldersResponse.data[HYDRA_MEMBER_KEY].map((f) => ({
        label: f.name,
        value: f.id
      }));
    } catch (err) {
      this.fetchFoldersStatus = 'error';
    }
  }

  @action.bound
  public getTemplateId(): string {
    const templateId = this.selectedOffer?.templates?.find((template) => {
      const { personal, shop } = template.conditions;
      return (
        personal === this.selectedPersonalTemplate &&
        shop === this.selectedShopTemplate
      );
    })?.id;
    return templateId || '';
  }

  @action.bound
  public getTemplateIdByCustomSize(): string {
    if (!this.customSize || !this.selectedOffer) {
      return '';
    }

    const { templates } = this.selectedOffer;
    const filteredTemplates =
      templates?.filter((template) => {
        return (
          template.conditions.personal === this.selectedPersonalTemplate &&
          template.conditions.shop === this.selectedShopTemplate
        );
      }) ?? [];
    const userInputRatio = getRatio(
      this.customSize.width,
      this.customSize.height
    );

    let closestTemplate = filteredTemplates[0];
    let minDifference = Math.abs(
      userInputRatio -
        getRatio(
          closestTemplate.conditions.width!,
          closestTemplate.conditions.height!
        )
    );

    for (const currentTemplate of filteredTemplates) {
      const difference = Math.abs(
        userInputRatio -
          getRatio(
            currentTemplate.conditions.width!,
            currentTemplate.conditions.height!
          )
      );

      if (difference < minDifference) {
        minDifference = difference;
        closestTemplate = currentTemplate;
      }
    }

    return closestTemplate.id;
  }

  @action.bound
  // tslint:disable-next-line: cognitive-complexity
  public async generatePDF() {
    if (!this.selectedFormat || !this.selectedOffer || !this.selectedTheme) {
      // tslint:disable-next-line:no-console
      console.error('Missing data to generate PDF');
      return;
    }
    const shops = this.selectedShops;
    const shopIds = (shops?.length && shops.map((s) => s.id)) || [];

    this.generatePDFStatus = 'loading';

    try {
      let pdfResponse = null;
      const { press } = this.selectedOffer;
      const requestParams: PostSupportPDFRequestBody = {
        templateId: press
          ? this.getTemplateIdByCustomSize()
          : this.getTemplateId(),
        startDate: this.selectedDates?.startDate || getCurrentDate(),
        endDate: this.selectedDates?.endDate || getCurrentDate()
      };

      if (this.selectedDistrict) {
        requestParams.departmentId = this.selectedDistrict.id;
      }
      if (this.selectedCities) {
        requestParams.cityIds = this.selectedCities.map((s) => s.id);
      }
      if (shopIds.length) {
        requestParams.shopIds = shopIds;
      }
      if (this.customSize) {
        requestParams.width = this.customSize.width;
        requestParams.height = this.customSize.height;
      }

      const logoFile = this.selectedMedias?.logo;
      const qrCodeFile = this.selectedMedias?.qrCode;
      if (logoFile || qrCodeFile) {
        const partnerName = this.selectedMedias?.name;
        if (!partnerName) {
          throw new Error('Missing data to generate PDF');
        }
        requestParams.partnerName = partnerName;
        if (logoFile) {
          requestParams.partnerLogo = logoFile;
        }
        if (qrCodeFile) {
          requestParams.partnerQrCode = qrCodeFile;
        }
        pdfResponse = await this.supportFunnelService.generatePdfWithFile(
          requestParams
        );
      } else {
        pdfResponse = await this.supportFunnelService.generatePdf(
          requestParams
        );
      }
      // Avoid user go back to another step before the response from server
      if (this.supportFunnelGlobalStore.currentStep.id === 'recap') {
        this.generatedPDF = pdfResponse.data;
      }

      this.generatePDFStatus = 'success';
    } catch (err) {
      this.generatePDFStatus = 'error';
    }
  }

  @action.bound
  public setSelectedTheme(theme: ThemeItem) {
    this.selectedTheme = theme;
    // reset next steps
    this.selectedDates = null;
    this.selectedMedias = null;
    this.selectedDistrict = null;
    this.selectedCityNameAlt = '';
    this.selectedCities = null;
    this.selectedInseeCity = null;
    this.selectedShops = null;
    this.shops = [];
    this.customSize = null;
    this.pageOrientation = null;
  }

  @action.bound
  public setSelectedFormat(format: FormatItem) {
    this.selectedFormat = format;
    // reset next steps
    this.selectedDates = null;
    this.selectedMedias = null;
    this.selectedDistrict = null;
    this.selectedCityNameAlt = '';
    this.selectedCities = null;
    this.selectedInseeCity = null;
    this.selectedShops = null;
    this.selectedOffer = null;
    this.shops = [];
  }
  @action.bound
  public setSelectedOffer(offer: OfferItem) {
    this.selectedOffer = offer;
    // reset next steps
    this.selectedDates = null;
    this.selectedMedias = null;
    this.selectedDistrict = null;
    this.selectedCityNameAlt = '';
    this.selectedCities = null;
    this.selectedInseeCity = null;
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedFolder(folder: FolderItem) {
    this.selectedFolder = folder;
    // reset next steps
    this.selectedDates = null;
    this.selectedDistrict = null;
    this.selectedCityNameAlt = '';
    this.selectedCities = null;
    this.selectedInseeCity = null;
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedDates(dates: SupportDates) {
    this.selectedDates = dates;
    // reset next steps
    this.selectedMedias = null;
    this.selectedDistrict = null;
    this.selectedCityNameAlt = '';
    this.selectedCities = null;
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedMedias(medias: MediasItem) {
    this.selectedMedias = medias;
    // reset next steps
    this.selectedDistrict = null;
    this.selectedCityNameAlt = '';
    this.selectedCities = null;
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedPersonalTemplate(type: SupportFunnelSteps) {
    this.selectedPersonalTemplate = type;
  }
  @action.bound
  public setSelectedShopTemplate(type: boolean) {
    this.selectedShopTemplate = type;
  }

  @action.bound
  public setSelectedDistrict(district: DistrictItem | null) {
    this.selectedDistrict = district;
    // reset next steps
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedCityNameAlt(freeText: string) {
    this.selectedCityNameAlt = freeText;
    // reset next steps
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedCities(cities: CityItem[] | null) {
    this.selectedCities = cities;
    // reset next step
    this.selectedShops = null;
    this.shops = [];
  }

  @action.bound
  public setSelectedInseeCity(selectedInseeCity: InseeCityItem | null) {
    this.selectedInseeCity = selectedInseeCity;
    // no next step, no reset
  }

  @action.bound
  public setSelectedShops(shops: ShopItem[] | null) {
    this.selectedShops = shops;
  }

  @action.bound
  public async setCustomSize(customSize: CustomSize) {
    this.customSize = customSize;
  }

  @action.bound
  public async setPageOrientation(pageOrientation: PageOrientation) {
    this.pageOrientation = pageOrientation;
  }

  @action.bound
  public resetSelectedSupport() {
    this.selectedFormat = null;
    this.selectedFolder = null;
    this.selectedDistrict = null;
    this.selectedCities = null;
    this.selectedShops = null;
    this.shops = [];
    this.selectedDates = null;
    this.selectedCityNameAlt = '';
    this.resetGeneratedPDF();
    this.customSize = null;
    this.pageOrientation = null;
  }

  @action.bound
  public resetExtraData() {
    this.isCropMark = false;
    this.referenceName = '';
    this.printingCompany = '';
  }

  @action.bound
  public resetGeneratedPDF() {
    this.generatedPDF = null;
    this.generatePDFStatus = 'idle';
  }

  @action.bound
  public async resetCustomSize() {
    this.customSize = null;
  }

  @action.bound
  public async resetPageOrientation() {
    this.pageOrientation = null;
  }

  @action.bound
  public setSupportName(supportName: string) {
    this.supportName = supportName;
  }

  @action.bound
  public setFetchCitiesStatus(status: FetchStatus) {
    this.fetchCitiesStatus = status;
  }
  @action.bound
  public setFetchDistrictsStatus(status: FetchStatus) {
    this.fetchDistrictsStatus = status;
  }

  @action.bound
  public setFetchShopsStatus(status: FetchStatus) {
    this.fetchShopsStatus = status;
  }

  @action.bound
  public setGeneratePDFStatus(status: FetchStatus) {
    this.generatePDFStatus = status;
  }

  @action.bound
  public async fetchSharedPdf(shareLinkId: string) {
    this.fetchSharedPdfStatus = 'loading';
    try {
      const response = await this.supportShareLinkService.getSharedSupport(
        shareLinkId
      );
      this.sharedPdf = response.data.support;
      this.fetchSharedPdfStatus = 'success';
    } catch (err) {
      this.fetchSharedPdfStatus = 'error';
    }
  }

  @action.bound
  public async createSupportFolder(name: string): Promise<FolderItem | null> {
    this.fetchPostFolderStatus = 'loading';
    try {
      const response = await this.supportFunnelService.createFolder(name);
      this.fetchPostFolderStatus = 'success';
      if (response.data.id && response.data.name) {
        const newFolder = {
          label: response.data.name,
          value: response.data.id
        };
        this.folders = [...this.folders, newFolder];
        return newFolder;
      }
    } catch (err) {
      this.fetchPostFolderStatus = 'error';
    }
    return null;
  }

  @action.bound
  public setNewSupportName(newName: string) {
    this.newSupportName = newName;
  }

  @action.bound
  public setIsCropMark(isCropMark: boolean) {
    this.isCropMark = isCropMark;
  }

  @action.bound
  public setReferenceName(name: string) {
    this.referenceName = name;
  }
  @action.bound
  public setPrintingCompany(name: string) {
    this.printingCompany = name;
  }

  @action.bound
  public async updateSupport(
    id: string,
    body: PostUpdateSupportPDFRequestBody
  ): Promise<SupportItem | null> {
    this.updateSupportStatus = 'loading';
    try {
      const response = await this.supportFunnelService.updateSupport(id, body);
      this.updateSupportStatus = 'success';
      if (response.data && response.data.id) {
        this.supportStore.updateElementSupport(response.data);
        return response.data;
      }
    } catch (err) {
      this.updateSupportStatus = 'error';
    }
    return null;
  }
}

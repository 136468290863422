import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import { LoginResponseData } from '../types';

export default class LoginService extends ApiService {
  public login(
    email: string,
    password: string
  ): Promise<AxiosResponse<LoginResponseData>> {
    return this.post('/login', {
      email,
      password
    });
  }

  public logout() {
    return this.get('/logout');
  }

  protected getServiceId() {
    return 'login';
  }
}

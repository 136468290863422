import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import {
  UserResponseData,
  UserSorting,
  GetUsersResponseData,
  UserFormData
} from '../types';

export const ITEMS_PER_PAGE = 12;

export default class UsersService extends ApiService {
  public getUser(uuid: string): Promise<AxiosResponse<UserResponseData>> {
    return this.get(`/users/${uuid}`);
  }

  public fetchUsers(
    page: number = 1,
    sorting: UserSorting,
    itemsPerPage: number = ITEMS_PER_PAGE
  ): Promise<AxiosResponse<GetUsersResponseData>> {
    return this.get('/users', {
      page,
      [`order[${sorting.field}]`]: sorting.direction,
      itemsPerPage
    });
  }

  public createUser(
    userData: UserFormData
  ): Promise<AxiosResponse<UserResponseData>> {
    return this.post('/users', {
      email: userData.email,
      password: userData.password,
      firstName: userData.firstName,
      middleName: userData.middleName,
      lastName: userData.lastName,
      roles: userData.roles,
      enabled: userData.enabled
    });
  }

  public updateUser(
    userData: UserFormData
  ): Promise<AxiosResponse<UserResponseData>> {
    return this.patch(`/users/${userData.id}`, {
      email: userData.email,
      password: userData.password,
      firstName: userData.firstName,
      middleName: userData.middleName,
      lastName: userData.lastName,
      roles: userData.roles,
      enabled: userData.enabled
    });
  }

  protected getServiceId() {
    return 'users';
  }
}

import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import { GetMediaShareLinkResponseData } from '../../api/interfaces/media';
import { CreateShareLinkResponseData } from '../../api/interfaces/library';

export default class MediaShareLinkService extends ApiService {
  public createShareLink(
    mediaId: string
  ): Promise<AxiosResponse<CreateShareLinkResponseData>> {
    return this.post(`/media_share_links`, {
      mediaId
    });
  }

  public getSharedMedia(
    shareLinkId: string
  ): Promise<AxiosResponse<GetMediaShareLinkResponseData>> {
    return this.get(`/media_share_links/${shareLinkId}`);
  }

  protected getServiceId() {
    return 'mediaShareLink';
  }
}

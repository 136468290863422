import Loader from '@bouygues/theme/src/components/modules/Loader/Loader';
import React, { lazy, Suspense, useEffect } from 'react';
import { render } from 'react-dom';

import '@bouygues/theme/src/styles/main.scss';
import 'core-js/es';
import 'moment/locale/fr';
import { GlobalStoreProvider } from './core/stores/global';
import Tracking from './core/utils/gaTrackingUtils';

// lazy load routing component to get a decent first payload
const Core = lazy(() => import('./core/App' /* webpackChunkName: "app" */));

function App() {
  useEffect(() => {
    Tracking.init();
  }, []);

  return (
    // use react's Suspense: config, routing and translations might still be loading
    <Suspense fallback={<Loader color="black" isFullScreen delayMs={400} />}>
      <GlobalStoreProvider>
        <Core />
      </GlobalStoreProvider>
    </Suspense>
  );
}

render(<App />, document.getElementById('print_app'));

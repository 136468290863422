import { AxiosResponse } from 'axios';
import ApiService from '../../api/services/ApiService';
import {
  GetCitiesResponseData,
  GetDistrictsResponseData,
  GetShopsResponseData,
  GetThemesResponseData,
  GetFoldersResponseData,
  PostSupportPDFRequestBody,
  PostUpdateSupportPDFRequestBody,
  FoldersResponseData
} from '../../api/interfaces/supportFunnel';
import SupportItem from '../../api/interfaces/support';

export const ITEMS_PER_PAGE = 30;

export default class SupportFunnelService extends ApiService {
  public getDistricts(params: {
    itemsPerPage: number;
    keyword: string;
  }): Promise<AxiosResponse<GetDistrictsResponseData>> {
    return this.get('/support_departments', params);
  }

  public getCities(params: {
    itemsPerPage: number;
    keyword?: string;
    'exists[cityEndAdslYear]'?: boolean;
  }): Promise<AxiosResponse<GetCitiesResponseData>> {
    return this.get('/insee_cities', params);
  }

  public getShops(params: {
    itemsPerPage: number;
    keyword: string;
  }): Promise<AxiosResponse<GetShopsResponseData>> {
    return this.get('/support_shops', params);
  }

  public getThemes(): Promise<AxiosResponse<GetThemesResponseData>> {
    return this.get('/support_themes');
  }

  public getFolders(): Promise<AxiosResponse<GetFoldersResponseData>> {
    return this.get('/support_folders');
  }

  public createFolder(
    name: string
  ): Promise<AxiosResponse<FoldersResponseData>> {
    return this.post('/support_folders', {
      name
    });
  }

  public generatePdf(
    body: PostSupportPDFRequestBody
  ): Promise<AxiosResponse<SupportItem>> {
    return this.post('/supports', body);
  }

  public generatePdfWithFile(
    body: PostSupportPDFRequestBody
  ): Promise<AxiosResponse<SupportItem>> {
    return this.postMultipartForm('/supports', body);
  }

  public updateSupport(
    id: string,
    body: PostUpdateSupportPDFRequestBody
  ): Promise<AxiosResponse<SupportItem>> {
    return this.patch(`/supports/${id}`, body);
  }

  protected getServiceId() {
    return 'support-funnel';
  }
}

import { action, computed, observable } from 'mobx';
import debounce from 'lodash/debounce';
import { MediaService } from '../services/MediaService';
import {
  MediaItem,
  MediaTypesItem,
  MediaProviderItem,
  MediaSearchItem,
  MediaProvider
} from '../../api/interfaces/media';
import { SelectedFilters } from '@bouygues/theme';
import MediaTypesService from '../services/MediaTypesService';
import MediaShareLinkService from '../services/MediaShareLinkService';
import {
  FetchStatus,
  HYDRA_MEMBER_KEY,
  HYDRA_TOTAL_ITEMS_KEY
} from '../../api/interfaces/common';
import {
  LibrarySorting,
  ordereableField,
  ViewMode
} from '../../api/interfaces/library';
import DashboardStore from '../../dashboard/stores/DashboardStore';

interface FileInfo {
  fileName?: any;
  fileCategory?: any;
  highlight?: string;
  uploadDate?: string;
}

export interface SelectedUploadFile {
  file: File | null;
  name: string;
  preview: string | null;
  mediaType: string;
  highlight?: string;
  uploadDate?: string;
}

export default class MediaStore {
  @observable public fetchStatus: FetchStatus = 'idle';
  @observable public fetchSearchStatus: FetchStatus = 'idle';
  @observable public fetchSharedMediaStatus: FetchStatus = 'idle';
  @observable public fetchSharedLinkStatus: FetchStatus = 'idle';

  @observable public medias: MediaItem[] = [];
  @observable public searchResults: MediaSearchItem[] = [];
  @observable public sharedMedia: MediaItem | null = null;
  @observable public mediaTypes: MediaTypesItem[] = [];
  @observable public mediaYears: number[] = [];
  @observable public mediaProviders: MediaProviderItem[] = [];
  @observable public mediaMaxUploadSize: number = 2e6; // 2MB - Fallback value
  @observable public viewMode: ViewMode = 'cards';
  @observable public mediasLength: number = -1;
  @observable public currentPage: number = 1;
  @observable public filters: SelectedFilters = {};
  @observable public mediaShareLinkId: string = '';
  @observable public sorting: LibrarySorting = {
    field: 'createdAt',
    direction: 'desc'
  };
  @observable public selectedUploadFile: SelectedUploadFile = {
    file: null,
    name: '',
    preview: null,
    mediaType: '',
    highlight: '',
    uploadDate: ''
  };
  @observable public addedMedia: MediaItem | null = null;

  private debouncedFetchMedias: () => void;

  constructor(
    private dashboardStore = new DashboardStore(),
    protected mediaService: MediaService = new MediaService(),
    private mediaTypesService: MediaTypesService = new MediaTypesService(),
    private mediaShareLinkService: MediaShareLinkService = new MediaShareLinkService()
  ) {
    this.debouncedFetchMedias = debounce(this.fetchMedias.bind(this), 300);
    this.deboundedFetchSearchResults = debounce(
      this.deboundedFetchSearchResults.bind(this),
      500
    );
  }

  @action.bound
  public async fetchMedias() {
    this.fetchStatus = 'loading';

    const mediasResponse = await this.mediaService.fetchMedias(
      this.currentPage,
      this.sorting,
      this.filters
    );

    this.fetchStatus = 'success';
    this.medias = mediasResponse.data[HYDRA_MEMBER_KEY];
    this.mediasLength = mediasResponse.data[HYDRA_TOTAL_ITEMS_KEY];
  }

  @action.bound
  public async fetchMediaTypes() {
    if (!this.mediaTypes.length) {
      const mediaTypesResponse = await this.mediaTypesService.getAll();
      this.mediaTypes = mediaTypesResponse.data[HYDRA_MEMBER_KEY];
    }
  }

  @action.bound
  public async fetchMediaYears() {
    if (!this.mediaYears.length) {
      const mediaYearsResponse = await this.mediaService.getMediaYears();
      this.mediaYears = mediaYearsResponse.data[HYDRA_MEMBER_KEY];
    }
  }

  @action.bound
  public async fetchMediaConfig() {
    if (!this.mediaProviders.length) {
      const mediaConfigResponse = await this.mediaService.getMediaConfig();
      this.mediaProviders = mediaConfigResponse.data.providers;

      // Get config chunk size from Server
      this.mediaMaxUploadSize = mediaConfigResponse.data.maxUploadSize;
    }
  }

  @computed get allowedMineTypesFileAndImage(): string[] {
    if (this.mediaProviders.length) {
      const listMimeTypes = this.mediaProviders
        .map((m) => [...m.allowedMimeTypes])
        .reduce((acc, item) => acc.concat(item), []);
      return listMimeTypes.filter(
        (val, idx) => listMimeTypes.indexOf(val) === idx
      );
    }
    return [];
  }

  @computed get mapExtensionMediaFilters() {
    let strToCheckDuplicate = '';
    const obj: { [k: string]: string[] } = {};

    this.mediaProviders.forEach((prov) => {
      Object.keys(prov.extensionMimeTypeMapper).forEach((extKey) => {
        const listExts = prov.extensionMimeTypeMapper[extKey];
        const strExts = `[${listExts.toString()}]`;

        if (!strToCheckDuplicate.includes(strExts)) {
          obj[extKey] = listExts;
          strToCheckDuplicate += strExts;
        }
      });
    });

    return obj;
  }

  @computed get mediaChunkFileUpload() {
    return this.mediaMaxUploadSize - 1000;
  }

  @action.bound
  public getMediaProviderByName(name: MediaProvider) {
    const mediaProvider = this.mediaProviders.find((p) => p.name === name);
    if (mediaProvider) {
      return mediaProvider;
    }
    return {
      name,
      allowedMimeTypes: [] as string[],
      allowedExtensions: [] as string[]
    };
  }

  @action.bound
  public async uploadMedia() {
    if (!this.selectedUploadFile.file) {
      return;
    }
    let response = null;
    const originFile = this.selectedUploadFile.file;
    const originFileName = encodeURIComponent(originFile.name);
    const fileType = originFile.type;
    const provider = this.mediaProviders.find((providers) => {
      return providers.allowedMimeTypes.includes(fileType);
    });

    if (!provider || !provider.name) {
      return;
    }

    const providerName = provider.name;
    const sessionId = Math.floor(Math.random() * 999999999);

    let done = false;
    let startWith = 0;

    while (true) {
      let endWith = startWith + this.mediaChunkFileUpload;
      if (
        endWith > originFile.size ||
        originFile.size < this.mediaChunkFileUpload
      ) {
        done = true;
        endWith = originFile.size;
      }
      const requestHeaders = {
        'Session-ID': sessionId,
        'Content-Range': `bytes ${startWith}-${endWith - 1}/${originFile.size}`,
        'Content-Disposition': `attachment; filename="${originFileName}"`,
        'Content-Type': originFile.type
      };
      response = await this.mediaService.uploadChunkMedia(
        originFile.slice(startWith, endWith),
        this.selectedUploadFile.name,
        this.selectedUploadFile.mediaType,
        providerName,
        this.selectedUploadFile.highlight,
        this.selectedUploadFile.uploadDate,
        requestHeaders
      );

      if (done) {
        break;
      }

      startWith = endWith;
    }

    this.addedMedia = response ? response.data : null;
    this.fetchMedias();
  }

  @action.bound
  public toggleViewMode() {
    this.viewMode = this.viewMode === 'cards' ? 'list' : 'cards';
  }

  @action.bound
  public onPageChange(newPage: number) {
    if (this.currentPage !== newPage) {
      this.debouncedFetchMedias();
    }
    this.currentPage = newPage;
  }

  @action.bound
  public onSortChange(field: ordereableField) {
    if (this.sorting.field === field) {
      this.sorting.direction =
        this.sorting.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sorting.field = field;
      this.sorting.direction = 'asc';
    }
    this.currentPage = 1;
    this.fetchMedias();
  }

  @action.bound
  public onFiltersChange(selectedFilters: SelectedFilters) {
    this.filters = selectedFilters;
    this.currentPage = 1;
    this.fetchMedias();
  }

  @action.bound
  public async deleteMedia(media: MediaItem) {
    try {
      this.fetchStatus = 'loading';
      await this.mediaService.deleteMedia(media.id);
      this.fetchMedias();
      // fetch media at dashboard screen
      this.dashboardStore.fetchMedia();
      this.fetchStatus = 'success';
    } catch (e) {
      this.fetchStatus = 'error';
    }
  }

  @action.bound
  public async selectUploadFile(file: File) {
    this.clearFileUpload();
    this.selectedUploadFile.file = file;
    this.selectedUploadFile.name = file.name;
    if (
      !file.name.toLowerCase().endsWith('.psd') &&
      (file.type.startsWith('video/') || file.type.startsWith('image/'))
    ) {
      this.selectedUploadFile.preview = URL.createObjectURL(file);
    }
  }

  @action.bound
  public async setFileInfo(fileInfo: FileInfo) {
    this.selectedUploadFile.mediaType = fileInfo.fileCategory;
    this.selectedUploadFile.name = fileInfo.fileName;
    if (fileInfo.highlight) {
      this.selectedUploadFile.highlight = fileInfo.highlight;
    }
    if (fileInfo.uploadDate) {
      this.selectedUploadFile.uploadDate = fileInfo.uploadDate;
    }
  }

  @action.bound
  public clearFileUpload() {
    this.selectedUploadFile = {
      file: null,
      name: '',
      preview: null,
      mediaType: '',
      highlight: '',
      uploadDate: ''
    };
    this.addedMedia = null;
  }

  @action.bound
  public async getShareLink(mediaId: string) {
    this.mediaShareLinkId = '';
    this.fetchSharedLinkStatus = 'loading';
    try {
      const response = await this.mediaShareLinkService.createShareLink(
        mediaId
      );
      this.mediaShareLinkId = response.data.id;
      this.fetchSharedLinkStatus = 'success';
    } catch (err) {
      this.fetchSharedLinkStatus = 'error';
    }
  }

  @action.bound
  public async setShareLinkId(shareLinkId: string) {
    this.mediaShareLinkId = shareLinkId;
  }

  @action.bound
  public getSearchString() {
    if (this.filters.name && this.filters.name.length) {
      return this.filters.name[0];
    }
    return '';
  }

  @action.bound
  public async fetchSharedMedia(shareLinkId: string) {
    this.fetchSharedMediaStatus = 'loading';
    try {
      const response = await this.mediaShareLinkService.getSharedMedia(
        shareLinkId
      );
      this.sharedMedia = response.data.media;
      this.fetchSharedMediaStatus = 'success';
    } catch (err) {
      this.fetchSharedMediaStatus = 'error';
    }
  }

  @action.bound
  public async fetchSearchResults({ name }: { name: string }) {
    this.fetchSearchStatus = 'loading';

    this.deboundedFetchSearchResults({ name });
  }

  @action.bound
  public async setFetchSearchStatus(status: FetchStatus) {
    this.fetchSearchStatus = status;
  }

  @action.bound
  private async deboundedFetchSearchResults({ name }: { name: string }) {
    const currentPage = 1;
    const itemsPerPage = 5;

    const mediasResponse = await this.mediaService.searchMedia(
      name,
      currentPage,
      itemsPerPage
    );

    this.fetchSearchStatus = 'success';
    this.searchResults = mediasResponse.data[HYDRA_MEMBER_KEY];
  }
}
